/* ******************************************************
   Main Sections POAP App
   ****************************************************** */
  
  .loading {
    background: linear-gradient(90deg, #e6e6e6 25%, #fff 37%, #e6e6e6 63%);
    border-radius: 1rem;
    background-size: 400% 100%;
    animation: loading 3s ease infinite;
  }
  @keyframes loading {
    0% {
      background-position: 100% 50%;
    }
    to {
      background-position: 0 50%;
    }
  }

.poap-app {
  background-color: $bg-color;

  .spinner {
    margin: 100px auto;
    grid-column: 1 / 3;
    width: 40px;
    height: 40px;
    position: relative;
  }
  
  .cube1, .cube2 {
    background-color: #6534ff;
    width: 15px;
    height: 15px;
    position: absolute;
    top: 0;
    left: 0;
    
    -webkit-animation: sk-cubemove 1.8s infinite ease-in-out;
    animation: sk-cubemove 1.8s infinite ease-in-out;
  }
  
  .cube2 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }
  
  @-webkit-keyframes sk-cubemove {
    25% { -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5) }
    50% { -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg) }
    75% { -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5) }
    100% { -webkit-transform: rotate(-360deg) }
  }
  
  @keyframes sk-cubemove {
    25% { 
      transform: translateX(42px) rotate(-90deg) scale(0.5);
      -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
    } 50% { 
      transform: translateX(42px) translateY(42px) rotate(-179deg);
      -webkit-transform: translateX(42px) translateY(42px) rotate(-179deg);
    } 50.1% { 
      transform: translateX(42px) translateY(42px) rotate(-180deg);
      -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
    } 75% { 
      transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
      -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
    } 100% { 
      transform: rotate(-360deg);
      -webkit-transform: rotate(-360deg);
    }
  }

  &.events-page {
    .header-events {
      padding: 0;
      @media (min-width: $bp-mw) {
        padding: 0 0 100px;
      }
    }
    main {
      padding: 0;
    }
    footer {
      margin: 0;
    }
  }

  .header-events {
    clear: both;
    h1 {
      margin: 0 0 10px;
    }
    p {
      margin-bottom: 20px;  
    }
  }
}

.gallery-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.5rem;
    grid-auto-flow: row;
}

.gallery-card {
  cursor: pointer;
  position: relative;
  box-shadow: 0 5px 16px rgba(100,100,100, 0.3);
  transition: box-shadow .2s ease;
  border-radius: .5rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

a.gallery-card:hover {
  text-decoration: none;
}

.gallery-search {
  position: relative;
  grid-column: 1 / 3;
  grid-row: 2 / 3;
  place-self: end stretch;
  margin-bottom: .5rem;
}

.gallery-search input {
  margin-bottom: 0;
}

.gallery-filter {
  grid-column: 1 / 3;
  place-self: end;
  width: 100%;
}

.gallery-card:hover {
  box-shadow: 0 5px 16px rgba(100,100,100, 0.3), 0 5px 22px rgba(100,100,100, 0.2);
}

@media (min-width: $bp-s) {
  .gallery-grid.activity-grid {
    grid-template-columns: repeat(1, 1fr);
    place-items: center;
  }
}


@media (min-width: $bp-m){ // 479 px
 
  .gallery-grid {
    grid-template-columns: repeat(3, 1fr);
    
    .gallery-search {
      grid-row: 1 / 2;
      grid-column: 1 / 2;
    }
    
    .gallery-filter {
      grid-column: 2 / 4;
    }
    .spinner {
      grid-column: 1 / 4;
    }
  }
  .gallery-grid.activity-grid {
    grid-template-columns: repeat(2, 1fr);
    place-items: center;
  }
}

@media (min-width: $bp-l){ //1140 px
  .gallery-grid{
    grid-template-columns: repeat(4, 1fr);
    
    .gallery-search {
      grid-column: 1 / 2;
    }
    
    .gallery-filter {
      grid-column: 3 / 5;
    }

    .spinner {
      grid-column: 1 / 5;
    }
  }
  .gallery-card {
    width: 250px;
    height: 100%;
  }
  .gallery-grid.activity-grid {
    grid-template-columns: repeat(3, 1fr);
    place-items: center;
  }
}

@media (max-width: $bp-m){ // 479 px

  .activitycards{
    flex-direction: column; 
  }

  .gallery-card{
   // background-color: #6534ff;
    width: 100%;
    margin: 0rem;
    
    
  }
  
}

.poap-app {
  header {
    height: 200px;
    background-image: url('../../assets/images/bg-header-m.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;

    -webkit-overflow-scrolling: touch;
    -webkit-transition: all 0.5s ease-in-out-ut;
    transition: all 0.5s ease-in-out;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    z-index: 100;
    top: -200px;
    position: static;
    width: 100%;
    padding: 20px 0 0;

    @media (min-width: $bp-sw) {
      background-image: url('../../assets/images/bg-header.png');
      padding: 0;
    }

    &.fixed {
      width: 100%;
      z-index: 100;

      position: fixed;
      top: 0;
      -webkit-overflow-scrolling: touch;
      -webkit-transition: all 0.5s ease-in-out;
      transition: all 0.5s ease-in-out;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;

      background-image: url('../../assets/images/bg-header-m-fixed.png');

      @media (min-width: $bp-sw) {
        background-image: url('../../assets/images/bg-header-fixed.png');
      }
    }

    .container {
      max-width: 470px;

      @media (min-width: $bp-mw) {
        max-width: 690px;
      }
    }

    .logo {
      margin: 10px 0 0 10px;
      float: left;
    }

    .page-title {
      text-align: right;
      @include x-rem(font-size, 40px);
      font-weight: $fontWeight-bold;
      color: $color-primary;
      font-family: $font-family-narrow;
      padding: 30px 30px 0;

      @media (max-width: $bp-m) {
        @include x-rem(font-size, 20px);
      }
    }
  }
}

/* ******************************************************
   App Content
   ****************************************************** */

.app-content {
  .content-event {
    max-width: 600px;
    margin: 0 auto;
    width: 100%;
    text-align: center;
    display: block;

    p {
      span {
        font-weight: $fontWeight-bold;
      }
    }
    .login-form {
      text-align: center;

      input[type='text'] {
        max-width: 500px;
        text-align: center;
      }
    }

    &.years {
      text-align: left;

      @media (min-width: $bp-mw) {
        margin: 0 auto 50px;
        max-width: 600px;
      }

      h1 {
        font-weight: $fontWeight-normal;
        @include x-rem(font-size, 20px);
        margin: 0 0 12px;

        span {
          font-weight: $fontWeight-bold;
          @include x-rem(font-size, 16px);

          @media (min-width: $bp-m) {
            @include x-rem(font-size, 20px);
          }
        }
      }

      p {
        max-width: none;
      }

      .event-year {
        margin-bottom: 30px;

        &:last-child {
          margin: 0;
        }

        h2 {
          color: $color-fourth;
          @include x-rem(font-size, 30px);
          font-family: $font-family-narrow;
          margin-bottom: 12px;
        }

        &.empty-year {
          img {
            width: 100%;
            max-width: 320px;
            margin: 0 auto 20px;
            display: block;
          }

          .image-description {
            width: 100%;
            margin: 0 auto;
            max-width: 320px;
            text-align: center;
            color: #b29ffa;

            @media (min-width: $bp-mw) {
              max-width: 360px;
              @include x-rem(font-size, 20px);
            }
          }
        }

        .events-logos {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;

          .event-circle {
            width: 95px;
            height: 95px;
            border-radius: 50%;
            overflow: hidden;
            margin: 0 12px 12px 0;
            background-color: $color-primary;
            -webkit-box-shadow: 0px 10px 30px -5px rgba($color-primary, 0.5);
            -moz-box-shadow: 0px 10px 30px -5px rgba($color-primary, 0.5);
            box-shadow: 0px 10px 30px -5px rgba($color-primary, 0.5);

            @media (min-width: $bp-mw) {
              margin: 0 24px 24px 0;
            }
          }
        }
      }
    }

    &.contact-form {
      text-align: left;

      @media (min-width: $bp-m) {
        margin: 0 auto 50px;
        max-width: 600px;
      }

      h1 {
        font-weight: $fontWeight-normal;
        @include x-rem(font-size, 20px);
        margin: 0 0 12px;
      }

      form {
        margin: 24px 0 0;

        input[type='text'],
        select,
        input[type='submit'] {
          max-width: none;
        }

        .form-group {
          margin: 0 0 24px;
          float: left;
          width: 100%;
        }
      }
    }
  }
}

.loading-content {
  width: 100%;
  padding: 20px 0;
  text-align: center;

  img {
    max-width: 38px;
  }
}

